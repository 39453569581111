<!-- 导航 -->
<template>
  <div :class="{ 'has-logo': showLogo }"
       class="sidebar-container">
    <!-- <div :class="{ 'has-logo': showLogo }"
       class="sidebar-container"
       :style="{'--theme': this.theme}"> -->
    <logo v-if="showLogo"
          :collapse="isCollapse" />
    <div class="sidebar-content">
      <el-scrollbar wrap-class="scrollbar-wrapper mb0-scrollbar">
        <!-- <el-menu :default-active="activeMenu"
               :collapse="isCollapse"
               :background-color="variables.menuBg"
               :text-color="variables.menuText"
               :unique-opened="false"
               :active-text-color="variables.menuActiveText"
               :collapse-transition="false"
               mode="vertical">
        <sidebar-item v-for="route in permission_routes"
                      :key="route.path"
                      :item="route"
                      :base-path="route.path" />
        </el-menu>-->
        <el-menu :default-active="activeMenu"
                 :collapse="isCollapse"
                 background-color="#021452"
                 text-color="#FFFFFF"
                 :unique-opened="true"
                 active-text-color="#FFFFFF"
                 :collapse-transition="false"
                 class="newmenu"
                 router>
          <el-menu-item index="/home"   v-if="token.role.name === 'admin'||token.role.name === 'visitor'">
            <i class="el-icon-menu"></i>
            <span slot="title">平台首页</span>
          </el-menu-item>
          <el-submenu index="/data-monitor/data-runtime"
                       v-if="token.role.name === 'admin'||token.role.name === 'visitor'"
                      :style="isCollapse ? 'padding-left:20px' : ''">
            <template slot="title">
              <!-- <i class="el-icon-location"></i> -->
              <i class="el-icon-menu"></i>
              <span>实时数据</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/data-monitor/data-runtime/index">实时数据</el-menu-item>
              <!-- <el-menu-item index="/data-monitor/data-runtime/alarm-list">报警列表</el-menu-item> -->
              <el-menu-item index="/data-monitor/data-runtime/controller-alarm">控制器故障</el-menu-item>
              <el-menu-item index="/data-monitor/data-runtime/decetor-alarm">探测器报警</el-menu-item>
            </el-menu-item-group>
            <!-- <el-menu-item-group>
              <el-menu-item index="/data-monitor/handle-alarm"
                >报警记录</el-menu-item
              >
            </el-menu-item-group>-->
          </el-submenu>

          <el-submenu index="/data-monitor/data-history"
           v-if="token.role.name === 'admin'||token.role.name === 'visitor'"
                      :style="isCollapse ? 'padding-left:20px' : ''">
            <template slot="title">
              <!-- <i class="el-icon-location"></i> -->
              <i class="el-icon-menu"></i>
              <span>历史数据</span>
            </template>
            <el-menu-item-group>
              <!-- <el-menu-item index="/data-monitor/data-history/handle-alarm">报警记录</el-menu-item> -->
              <el-menu-item index="/data-monitor/data-history/controller-record">控制器记录</el-menu-item>
              <el-menu-item index="/data-monitor/data-history/decetor-record">探测器记录</el-menu-item>
              <el-menu-item index="/data-monitor/data-history/sms-record">短信记录</el-menu-item>
              <el-menu-item index="/data-monitor/data-history/recharge-record">充值记录</el-menu-item>
              <el-menu-item index="/data-monitor/data-history/history-data">历史数据</el-menu-item>
            </el-menu-item-group>
            <!-- <el-menu-item-group>
              <el-menu-item index="/data-monitor/handle-alarm"
                >报警记录</el-menu-item
              >
            </el-menu-item-group>-->
          </el-submenu>

          <el-menu-item index="/device-manage/index" v-if="token.role.name === 'admin'">
            <i class="el-icon-menu"></i>
            <span slot="title">设备管理</span>
          </el-menu-item>
          <el-menu-item index="/platform-admin/index" 
          v-if="token.role.name === 'superAdmin' || token.role.name === 'productionAdmin'">
            <i class="el-icon-menu"></i>
            <span slot="title">平台管理</span>
          </el-menu-item>
          

          <el-menu-item index="/platform-admin/invoice-manage" 
          v-if="token.role.name === 'superAdmin' || token.role.name === 'financialAdmin'">
            <i class="el-icon-menu"></i>
            <span slot="title">发票上传</span>
          </el-menu-item>
          <!--        <el-menu-item index="/notify-config/index">-->
          <!--          <i class="el-icon-menu"></i>-->
          <!--          <span slot="title">通知配置</span>-->
          <!--        </el-menu-item>-->
          <el-submenu index="/account-manage"
                      v-if="token.role.name === 'admin' || token.role.name === 'superAdmin'"
                      :style="isCollapse ? 'padding-left:20px' : ''">
            <template slot="title">
              <!-- <i class="el-icon-location"></i> -->
              <i class="el-icon-menu"></i>
              <span>账号管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/account-manage/depart-manage">部门管理</el-menu-item>
              <el-menu-item index="/account-manage/user-manage">子账户管理</el-menu-item>
              <el-menu-item index="/account-manage/contact-manage">联系人管理</el-menu-item>
              <!--
              <el-menu-item index="/account-manage/role-manage">角色管理</el-menu-item>
              -->
            </el-menu-item-group>
            <!-- <el-menu-item-group>
            <el-menu-item index="/account-manage/role-manage"
              >角色管理</el-menu-item
            >
            </el-menu-item-group>-->
            <!-- <el-menu-item-group>
              <el-menu-item index="/account-manage/user-manage"
                >子账户管理</el-menu-item
              >
            </el-menu-item-group>
            <el-menu-item-group>
              <el-menu-item index="/account-manage/contact-manage"
                >联系人管理</el-menu-item
              >
            </el-menu-item-group>-->
          </el-submenu>
          <el-menu-item index="/message-manage/index"
           v-if="token.role.name === 'admin'||token.role.name === 'visitor'"
          
          >
            <i class="el-icon-menu"></i>
            <span slot="title">消息通知</span>
          </el-menu-item>

          <!-- <el-submenu index="/index"
                      v-if="token.role.name === 'superAdmin'"
                      :style="isCollapse ? 'padding-left:20px' : ''">

               
            <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>平台管理</span> -->
            <!-- </template> -->
            <!-- <el-menu-item-group>
                  <el-menu-item index="/platform-admin/index">平台设备管理</el-menu-item>
            </el-menu-item-group> -->
          <!-- </el-submenu> -->

          
          
           <el-menu-item index="/my-invoice/index" v-if="token.role.name === 'admin'">
            <i class="el-icon-menu"></i>
            <span slot="title">我的发票</span>
          </el-menu-item> 


          
          
          <!-- <sidebar-item
          v-for="route in $router.options.routes"
          :key="route.path"
          :item="route"
          :base-path="route.path"
          />-->
        </el-menu>
      </el-scrollbar>
    </div>

    <!-- <template v-for="(item, index) in $router.options.routes" v-if="item.hidden">
      <el-submenu :index="item.path" v-if="item.children && item.children.length > i">

      </el-submenu>
    </template>-->

    <!-- 退出 -->
    <!--<div class="logout-btn" :collapse="isCollapse">
      <el-button type="text" @click="handleLogout">
        <img src="@/assets/images/app_images/logout.png" alt="" />
        <span class="btn-text" v-if="!isCollapse">退出登录</span>
      </el-button>
    </div>-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mapState } from "vuex";
// import variables from "@/styles/variables.scss";
// import SidebarItem from "./SidebarItem";
import Logo from "./Logo";
export default {
  components: {
    Logo,
    // SidebarItem,
  },
  data() {
    return {
      activeIndex: "/",
      themeLight: ''
    };
  },
  computed: {
    ...mapGetters(["permission_routes","sidebar"]),
    ...mapState({
      showLogo: (state) => state.settings.sidebarLogo,
      token: (state) => state.user.token,
      //theme: (state) => state.settings.theme,
    }),
    activeMenu() {
      const route=this.$route;
      const { meta,path }=route;
      if(meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
    // variables() {
    //   return variables;
    // },
  },
  watch: {
    $route() {
      this.setCurrentRoute();
    },
  },
  created() {
    this.setCurrentRoute();
    // this.themeLight=this.LightenDarkenColor(this.theme,100);
  },
  methods: {
    setCurrentRoute() {
      this.activeIndex=this.$route.path;
    },
    LightenDarkenColor(col,amt) {
      var usePound=false;
      if(col[0]=="#") {
        col=col.slice(1);
        usePound=true;
      }
      var num=parseInt(col,16);
      var r=(num>>16)+amt;
      if(r>255) r=255;
      else if(r<0) r=0;

      var b=((num>>8)&0x00FF)+amt;
      if(b>255) b=255;
      else if(b<0) b=0;

      var g=(num&0x0000FF)+amt;
      if(g>255) g=255;
      else if(g<0) g=0;

      return (usePound? "#":"")+(g|(b<<8)|(r<<16)).toString(16);
    }
    /** 退出 */
    // handleLogout() {
    //   // this.$router.push("/");
    //   // this.$router.push("/login");
    //   this.$store.dispatch("user/logout");
    //   location.reload();
    // },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-container {
  .sidebar-content {
    height: calc(100% - 8.125rem /* 130/16 */);
  }
}
</style>

<style lang="scss">
.sidebar-container {
  // position: relative;
  .scrollbar-wrapper {
    height: 100%;
    padding: 0 !important;
  }

  .logout-btn {
    position: absolute;
    bottom: 10px;
    left: 10px;
    img,
    .btn-text {
      vertical-align: middle;
    }

    .btn-text {
      color: #ffffff;
    }
  }
  .newmenu {
    border-right: none;
  }
}
</style>
