import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/** Layout */
import Layout from "@/layout";

/**
 * constantRoutes
 */
export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/redirect/index"),
      },
    ],
  },
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/register",
    component: () => import("@/views/register/index"),
    hidden: true,
    meta: {
      title: "注册账户",
    },
  },

  {
    path: "/validate",
    component: () => import("@/views/validate/index"),
    hidden: true,
    meta: {
      title: "找回账户",
    },
  },

  {
    path: "/reset-pwd",
    component: () => import("@/views/retrieve/reset-pwd"),
    hidden: true,
    meta: {
      title: "重新设置密码",
    },
  },



  {
    path: "/verific-device",
    component: () => import("@/views/register/verific-device"),
    hidden: true,
    meta: {
      title: "注册账户——设备号验证",
    },
  },
  {
    path: "/retrieve-first",
    component: () => import("@/views/retrieve/retrieve-first"),
    hidden: true,
    meta: {
      title: "找回密码",
    },
  },
  {
    path: "/retrieve-second",
    component: () => import("@/views/retrieve/retrieve-second"),
    hidden: true,
    meta: {
      title: "找回密码",
    },
  },
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/home/index"),
        name: "Home",
        meta: {
          title: "平台首页",
          icon: "home",
          affix: true,
        },
      },
    ],
  },
  {
    path: "/device-manage",
    component: Layout,
    children: [
      {
        path: "index",
        component: () => import("@/views/device-manage/index"),
        name: "DeviceManage",
        meta: {
          title: "设备管理",
          icon: "device",
        },
      },
      {
        path: "detail",
        component: () => import("@/views/device-manage/detail"),
        name: "DeviceDetail",
        hidden: true,
        meta: {
          title: "设备详情",
          icon: "device",
        },
      },
      {
        path: "add-device",
        component: () => import("@/views/device-manage/add-device"),
        name: "AddDevice",
        hidden: true,
        meta: {
          title: "添加设备",
          icon: "device",
        },
      },
      {
        path: "WeChat-Pay",
        component: () => import("@/views/device-manage/WeChat-Pay"),
        name: "WeChat-Pay",
        hidden: true,
        meta: {
          title: "续费",
          icon: "pay",
        },
      },
      {
        path: "edit-probe",
        component: () => import("@/views/device-manage/edit-probe"),
        name: "EditProbe",
        hidden: true,
        meta: {
          title: "编辑探头",
          icon: "device",
        },
      },
      {
        path: "alarm-setting",
        component: () => import("@/views/device-manage/alarm-setting"),
        name: "AlarmSetting",
        hidden: true,
        meta: {
          title: "报警设置",
          icon: "device",
        },
      },
      {
        path: "set-alarm-notify",
        component: () => import("@/views/device-manage/set-alarm-notify"),
        name: "SetAlarmNotify",
        hidden: true,
        meta: {
          title: "报警设置通知",
          icon: "device",
        },
      },
    ],
  },
  {
    path: "/data-monitor/data-runtime",
    component: Layout,
    children: [
      {
        path: "index",
        component: () => import("@/views/data-monitor/data-runtime/index"),
        name: "DataMonitor",
        meta: {
          title: "实时数据",
          icon: "data",
        },
      },
      // {
      //   path: "alarm-list",
      //   component: () => import("@/views/data-monitor/data-runtime/alarm-list"),
      //   name: "AlarmList",
      //   meta: {
      //     title: "报警列表",
      //     icon: "data",
      //   },
      // },
      {
        path: "controller-alarm",
        component: () => import("@/views/data-monitor/data-runtime/controller-alarm"),
        name: "ControllerArlarm",
        meta: {
          title: "控制器故障",
          icon: "data",
        },
      },
      {
        path: "decetor-alarm",
        component: () => import("@/views/data-monitor/data-runtime/decetor-alarm"),
        name: "DecetorAlarm",
        meta: {
          title: "探测器报警",
          icon: "data",
        },
      },
    ],
  },
  {
    path: "/data-monitor/data-history",
    component: Layout,
    children: [
      {
        path: "history-data",
        component: () => import("@/views/data-monitor/data-history/history-data"),
        name: "HistoryData",
        meta: {
          title: "历史数据",
          icon: "data",
        },
      },
      {
        path: "handle-alarm",
        component: () => import("@/views/data-monitor/data-history/handle-alarm"),
        name: "HandleAlarm",
        meta: {
          title: "报警记录",
          icon: "data",
        },
      },
      {
        path: "controller-record",
        component: () => import("@/views/data-monitor/data-history/controller-record"),
        name: "ControllerRecord",
        meta: {
          title: "控制器记录",
          icon: "data",
        },
      },
      {
        path: "decetor-record",
        component: () => import("@/views/data-monitor/data-history/decetor-record"),
        name: "DecetorRecord",
        meta: {
          title: "探测器记录",
          icon: "data",
        },
      },

      {
        path: "sms-record",
        component: () => import("@/views/data-monitor/data-history/sms-record"),
        name: "SmsRecord",
        meta: {
          title: "短信记录",
          icon: "data",
        },
      },
      {
        path: "recharge-record",
        component: () => import("@/views/data-monitor/data-history/recharge-record"),
        name: "RechargeRecord",
        meta: {
          title: "充值记录",
          icon: "data",
        },
      },

      {
        path: "platform-admin",
        component: () => import("@/views/device-manage/add-device"),
        name: "PlatformAdmin1",
        meta: {
          title: "平台管理",
          icon: "platform",
          // affix: true,
        },
      },

    ],
  },
  {
    path: "/account-manage",
    component: Layout,
    children: [
      {
        path: "depart-manage",
        component: () => import("@/views/account-manage/depart-manage"),
        name: "DepartManage",
        meta: {
          title: "部门管理",
          icon: "user",
        },
      },
      {
        path: "role-manage",
        component: () => import("@/views/account-manage/role-manage"),
        name: "RoleManage",
        meta: {
          title: "角色管理",
          icon: "user",
        },
      },
      {
        path: "user-manage",
        component: () => import("@/views/account-manage/user-manage"),
        name: "UserManage",
        meta: {
          title: "子账户管理",
          icon: "user",
        },
      },
      {
        path: "contact-manage",
        component: () => import("@/views/account-manage/contact-manage"),
        name: "ContactManage",
        meta: {
          title: "联系人管理",
          icon: "contact",
        },
      },
      {
        path: "user-center",
        component: () => import("@/views/account-manage/user-center"),
        name: "UserCenter",
        hidden: true,
        meta: {
          title: "个人中心",
          icon: "user",
        },
      },
    ],
  },
  {
    path: "/message-manage",
    component: Layout,
    children: [
      {
        path: "index",
        component: () => import("@/views/message-manage/index"),
        name: "MessageManage",
        meta: {
          title: "消息通知",
          icon: "message",
        },
      },
      {
        path: "detail",
        component: () => import("@/views/message-manage/detail"),
        name: "Detail",
        meta: {
          title: "消息详情",
          icon: "message",
        },
      },
    ],
  },
  {
    path: "/notify-config",
    component: Layout,
    children: [
      {
        path: "index",
        component: () => import("@/views/notify-config/index"),
        name: "NotifyConfig",
        meta: {
          title: "通知配置",
          icon: "message",
        },
      },
    ],
  },



  {
    path: "/platform-admin",
    component: Layout,
    redirect: "/platform-admin/index",
    children: [
      {
        path: "index",
        component: () => import("@/views/platform-admin/index"),
        name: "PlatformAdmin",
        meta: {
          title: "平台管理",
          icon: "platform",
          // affix: true,
        },
      },
    ],
  },




  {
    path: "/platform-admin",
    component: Layout,
    redirect: "/platform-admin/invoice-manage",
    children: [
      {
        path: "invoice-manage",
        component: () => import("@/views/platform-admin/invoice-manage"),
        name: "InvoiceManage",
        meta: {
          title: "发票管理",
          icon: "platform",
          // affix: true,
        },
      },
    ],
  },

  
  {
    path: "/my-invoice",
    component: Layout,
    redirect: "/my-invoice/index",
    children: [
      {
        path: "index",
        component: () => import("@/views/my-invoice/index"),
        name: "MyInvoice",
        meta: {
          title: "我的发票",
          icon: "MyInvoice",
          // affix: true,
        },
      },
    ],
  },


// {
//   path: "/platform-admin",
//   component: Layout,
//   children: [
//     {
//       path: "platform-admin",
//       component: () => import("@/views/platform-admin/index"),
//       name: "PlatformAdmin",
//       meta: {
//         title: "平台管理",
//         icon: "platform",
//       },
//     },


//   ],
// },







  // {
  //   path: "*",
  //   component: NotFound,
  //   meta: {
  //     title: "找不到页面",
  //   },
  // },
];

/**
 * asyncRouters
 */
export const asyncRoutes = [];

const createRouter = () =>
  new Router({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

export default router;
