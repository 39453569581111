import defaultSettings from "@/settings";
import Cookies from "js-cookie";

const { tagsView, fixedHeader, sidebarLogo } = defaultSettings;

const settings = {
    state: {
        theme: Cookies.get("theme") ? Cookies.get("theme") : '#021452',
        tagsView: tagsView,
        fixedHeader: fixedHeader,
        sidebarLogo: sidebarLogo,
    },
    mutations: {
        CHANGE_SETTING: (state, { key, value }) => {
            if (state.hasOwnProperty(key)) {
                state[key] = value
                Cookies.set("theme", value);
            }
        }
    },
    actions: {
            changeSetting({ commit }, data) {
            commit('CHANGE_SETTING', data)
        },
    }
};

export default settings;