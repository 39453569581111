import router from "./router";
import store from "./store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

NProgress.configure({
  showSpinner: false,
});

const whiteList = [
  "/login",
  "/register",
  "/validate",
  "/retrieve-first",
  "/verific-device",
  "/retrieve-first",
  "/retrieve-second",
  "/reset-pwd",
];

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  const hasToken = store.state.user.token;
  if (hasToken) {
  
    if (to.path === "/login"&&(hasToken.role.name=="visitor"||hasToken.role.name=="admin")) {
      next({
        path: "/",
      });
      // console.log(hasToken.role.name)
    
      NProgress.done();
    } else if (to.path === "/login"&&hasToken.role.name=="superAdmin"){
      next({
        path: "/platform-admin"
      })
      NProgress.done();
      // console.log(hasToken.role.name)
    }
    else if (to.path === "/login"&&hasToken.role.name=="productionAdmin"){
      next({
        path: "/platform-admin"
      })
      NProgress.done();
      // console.log(hasToken.role.name)
    }
    else if (to.path === "/login"&&hasToken.role.name=="financialAdmin"){
      next({
        path: "/platform-admin/invoice-manage"
      })
      NProgress.done();
      // console.log(hasToken.role.name)
    }
    
    else {
      next();
      NProgress.done();
    }
    if (to.meta.title) {
      document.title = to.meta.title;
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next("/login");
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});
